import React from "react";
import { Container, Paper, Grid, Typography, Divider , Button } from "@mui/material";
import SignUpForm from "../Components/SignUp/SignUpForm";
import styles from "./SignUpPage.module.css";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
const SignUpPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onLoginClick = () => {
    if(location.search.includes("redirect")){
      navigate(`/login?redirect=${searchParams.get("redirect")}`)
    } else {
      navigate("/login");
    }
  }

  return (
    <div className={styles.parentDiv} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/doneRightLoginBG.png)`}}>
        <Paper
          elevation={3}
          className={styles.paperStyle}
        >
          <Grid container>
          <Grid item xs={12} md={6}>
            <img className={styles.image} src={`${process.env.REACT_APP_IMAGE_URL}E_SERVICE/doneRightRegisterImg.png`} alt="Sign Up image" loading='lazy' />
          </Grid>
          <Grid item xs={12} md={6}>
          <div className={styles.formContainer}>
            <img className={styles.logo} alt="logo" src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/doneRightLogo.png`} loading='lazy'/>
            <Typography variant="h6" component="h3" className={styles.signUp}>
              Welcome To Done Right
            </Typography>
            <SignUpForm className={styles.formContainer} />
            <Divider sx={{ marginTop: "20px" }}>OR</Divider>
            {/* <Link to="/login"> */}
              <Button className={styles.formItem} onClick={() => onLoginClick()} color="secondary" fullWidth>
                Already have an account ?
              </Button>
            {/* </Link> */}
          </div>
          </Grid>
          </Grid>
        </Paper>
    </div>
  );
};

export default SignUpPage;
